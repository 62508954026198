import { Session } from '@aspendental/shared-components-web';
import Bugsnag from '@bugsnag/js';
import { BugsnagPluginReactResult } from '@bugsnag/plugin-react';
import { appWithTranslation } from 'next-i18next';
import type { AppProps, NextWebVitalsMetric } from 'next/app';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

import { ContextProviders } from '@src/components/shared/providers/ContextProviders';
import { Layout } from '@src/components/templates/layout';
import { getLogger } from '@src/lib/logging/logger';
import { initBugsnag } from '@src/services/bugsnag';

import '@contentful/live-preview/style.css';

import '@aspendental/shared-components-web/lib/fonts.css';
import '@aspendental/shared-components-web/lib/global.css';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/pagination';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/navigation';
import 'swiper/swiper.min.css';

export function reportWebVitals({ id, name, label, value }: NextWebVitalsMetric) {
	const logger = getLogger('client');
	logger.info({
		name,
		category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
		value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
		id, // id unique to current page load
	});
}

type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout;
};

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
	const router = useRouter();
	const config = pageProps?.config as BrandThemeConfig;
	const brandName = config?.name || 'Aspen Dental';
	initBugsnag(pageProps.env, brandName);

	const BugsnagReactPlugin = Bugsnag.getPlugin('react') as BugsnagPluginReactResult;
	const BugsnagErrorBoundary = BugsnagReactPlugin.createErrorBoundary(React);
	useEffect(() => {
		// when component is mounting we remove server side rendered css:
		const jssStyles = document.querySelector('#jss-server-side');

		if (jssStyles && jssStyles.parentNode) {
			jssStyles.parentNode.removeChild(jssStyles);
		}

		router.events.on('routeChangeComplete', () => null);

		return () => {
			router.events.off('routeChangeComplete', () => null);
		};
	}, [router.events]);
	const getLayout =
		Component.getLayout ??
		((page) => {
			const { features } = pageProps?.config as BrandThemeConfig;
			return (
				<Layout
					locale={router.locale || 'en-US'}
					preview={pageProps.preview}
					appData={pageProps.appData}
					isFooterLite={features.footer.lite}
					isFooterAlt={features.footer.alt}
					withSimpleLayout={features.footer.withSimpleLayout}
					footerData={pageProps.footerData}
					showHeaderNavMenu={pageProps.showHeaderNavMenu}
					breadcrumbsData={pageProps.breadcrumbsData}
					withFixedHeader={!features.header.hideHeaderOnScroll}
				>
					{page}
				</Layout>
			);
		});
	const environment = pageProps?.env?.ENVIRONMENT_NAME || 'prod';
	return (
		<BugsnagErrorBoundary>
			<Session>
				<ContextProviders
					config={pageProps.config}
					preview={pageProps.preview}
					environment={environment}
					user={pageProps.user}
					onPageScheduling={pageProps?.onPageScheduling || false}
					pageTheme={pageProps?.page?.pageTheme}
					defaultToCdn={pageProps?.config?.featureFlags?.defaultToCdn}
				>
					{getLayout(<Component {...pageProps} />)}
				</ContextProviders>
			</Session>
		</BugsnagErrorBoundary>
	);
};

export default appWithTranslation(App);
